import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Box, Snackbar, Tooltip, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as ConfigireDsIcon } from "../../assets/icons/configureDs.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch, useSelector } from "react-redux";
import "./dataSourcesDialog.scss";
import api from "../../apiInterceptor";
import ConfigureDatasource from "components/configureDatasource/configureDatasource";
import ConfigureFeatureMap from "components/configureFeatureMap/configureFeatureMap";
import ConfigureValueMap from "components/configureValueMap/configureValueMap";
const dataSourceMapTestData = require("../../assets/apiTestData/dsInfo.json");
const uploadDataAPITestData = require("../../assets/apiTestData/success-test-data.json");
const getfecfgAPITestData = require("../../assets/apiTestData/getfecfg-test-data.json");

const DatasourcesDialog = ({ loadPlayground }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [dataMapInfo, setDataMapInfo] = useState(null);
  const [sourceType, setSourceType] = useState(null);
  const [dsKeys, setDsKeys] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [showFeatureMap, setShowFeatureMap] = useState(false);
  const [showValueMap, setShowValueMap] = useState(false);
  const [isdatasourceUpdated, setIsdatasourceUpdated] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedDatasource, setSelectedDatasource] = useState(null);
  const [dsData, setDsData] = useState({});
  const [selectedDsProjVersion, setSelectedDsProjVersion] = useState(null);
  const [currentCfgInfo, setCurrentCfgInfo] = useState(null);
  const [dsMap, setDsMap] = useState(null);
  const [isBodyParam, setIsBodyParam] = useState("no");
  const [isLoginBodyParam, setIsLoginBodyParam] = useState("no");
  let uploadedFile = useRef(null);
  const showDatasourcesDialog = useSelector(
    (state) => state.showDatasourcesDialog
  );
  const contentRef = useRef(null);
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedProjFg = useSelector((state) => state.selectedFeatureGroup);
  const fileConfigs = useSelector((state) => state.fileConfigs);
  const databaseConfigs = useSelector((state) => state.databaseConfigs);
  const apiConfigs = useSelector((state) => state.apiConfigs);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const pipeline = useSelector((state) => state.pipeline);
  const dispatch = useDispatch();
  const { updateShowDatasourcesDialog, updateIsLoading, updateSnackbarMsg } =
    bindActionCreators(actionCreators, dispatch);
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };

  useEffect(() => {
    getDatasources();
  }, []);

  const getDatasources = async () => {
    try {
      const apiUrl = BASE_API_URL + "fetchfgdmap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedProjFg,
      };
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: dataSourceMapTestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      const dataSourcesMap = response.data.data.posts[0].dataMap;
      setDataMapInfo(dataSourcesMap);
      setDsKeys(Object.keys(dataSourcesMap));
    } catch (error) {
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const handleClose = () => {
    updateShowDatasourcesDialog(false);
    updateSnackbarMsg(null);
    if (isdatasourceUpdated) {
      loadPlayground();
    }
  };

  const initializeDsMap = async (dsData) => {
    const dsProjKey = dsData.projectKey;
    const dsProjVersion = dsData.projVersion;
    const dsProjFg = dsData.projFg;
    if (
      dsProjKey !== selectedProjectKey ||
      dsProjVersion !== selectedProjVersion ||
      dsProjFg !== selectedProjFg
    ) {
      try {
        const apiUrl = BASE_API_URL + "getfecfg";
        const headers = {
          "Content-type": "application/json",
          Accept: "text/plain",
        };
        const payload = {
          projectKey: dsProjKey,
          cfgProjectKey: dsProjKey,
          projVersion: dsProjVersion,
          projFg: dsProjFg,
        };
        updateIsLoading(true);
        let response = {};
        if (USING_TEST_DATA) {
          response = {
            data: getfecfgAPITestData,
          };
        } else {
          response = await api.post(apiUrl, payload, {
            headers: headers,
          });
        }
        updateIsLoading(false);
        if (response.data.status === 200) {
          const feCfg = response.data.data.posts[0];
          setDsMap(feCfg.clientMetaData.dsMap);
        } else if (response.data.status === 404) {
          if (response.data.data.reason) {
            updateSnackbarMsg(response.data.data.reason);
          } else {
            updateSnackbarMsg("Something went wrong. Please try again later");
          }
        }
      } catch (error) {
        console.log(error);
        updateIsLoading(false);
        const errorMessage =
          "Something went wrong. Please contact the administrator";
        updateSnackbarMsg(errorMessage);
      }
    } else {
      setDsMap(pipeline.clientMetaData.dsMap);
    }
  };

  const configureDs = (dsData) => {
    setDsData(dsData);
    setSelectedDatasource(dsData.dsKey);
    setSelectedDsProjVersion(dsData.projVersion);
    updateSourceType(dsData.dataCfg.cfg.sourceType);
    setCurrentCfgInfo(dsData.dataCfg.cfg);
    initializeDsMap(dsData);
    setShowTable(false);
  };

  const configureFeatMap = (dsData) => {
    setSelectedDatasource(dsData.dsKey);
    setDsData(dsData);
    setShowTable(false);
    setShowFeatureMap(true);
  };

  const configureValueMap = (dsData) => {
    setSelectedDatasource(dsData.dsKey);
    setDsData(dsData);
    setShowTable(false);
    setShowValueMap(true);
  };

  const updateSourceType = (srcType) => {
    setSourceType(srcType);
  };

  const updateSaveDisabling = (isDisabled) => {
    setIsSaveDisabled(isDisabled);
  };

  const goBack = () => {
    setShowTable(true);
    setShowFeatureMap(false);
    setShowValueMap(false);
    setSelectedDatasource(null);
    setCurrentCfgInfo(null);
    setSelectedDsProjVersion(null);
    setDsMap(null);
    getDatasources();
    setDsData({});
  };

  const checkJsonValidity = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedDatasource !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkHostNameValidity = (value) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!(ipRegex.test(value) || urlRegex.test(value))) {
      return false;
    } else {
      return true;
    }
  };

  const getFileUploadFormData = (newFileCfgs) => {
    newFileCfgs["sourceType"] = "file";
    newFileCfgs["dataPath"] =
      newFileCfgs.hostType === "local"
        ? newFileCfgs.file.name
        : newFileCfgs.dataPath;
    return newFileCfgs;
  };

  const handleSave = async () => {
    const apiUrl = BASE_API_URL + "perfdatamap";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    let dataCfg = {
      type: "add",
    };
    dataCfg["cfg"] =
      sourceType === "file"
        ? fileConfigs
        : sourceType === "db"
        ? getUpdatedDBFormData()
        : getUpdatedAPIConfigs();
    const payload = {
      projectKey: dsData.projectKey,
      projVersion: dsData.projVersion,
      projFg: dsData.projFg,
      dSource: selectedDatasource,
      dataCfg: dataCfg,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        goBack();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const getUpdatedAPIConfigs = () => {
    const newCfgs = structuredClone(apiConfigs);
    if (newCfgs.subType === "outgoing") {
      if (newCfgs.loginRequired === "yes") {
        if (isLoginBodyParam === "yes") {
          newCfgs.loginAPI.body = "";
        } else {
          if (newCfgs.loginAPI.method === "post") {
            newCfgs.loginAPI.body = JSON.parse(newCfgs.loginAPI.body);
          } else {
            newCfgs.loginAPI.body = "";
          }
          newCfgs.loginAPI.bodyParams = [];
        }
      }
      if (isBodyParam === "yes") {
        newCfgs.dataAPI.body = "";
      } else {
        if (newCfgs.dataAPI.method === "post") {
          newCfgs.dataAPI.body = JSON.parse(newCfgs.dataAPI.body);
        } else {
          newCfgs.dataAPI.body = "";
        }
        newCfgs.dataAPI.bodyParams = [];
      }
    }
    return newCfgs;
  };

  const uploadData = async (payload) => {
    const apiUrl = BASE_API_URL + "upload-data";
    const headers = {};
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: uploadDataAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, {
          headers: headers,
          data: uploadedFile.current,
        });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        handleSave();
        setIsdatasourceUpdated(true);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const getUpdatedDBFormData = (newCfgs) => {
    if (!newCfgs) {
      newCfgs = structuredClone(databaseConfigs);
    }
    if (newCfgs["rowSelInfo"] === "identifier") {
      newCfgs["idList"] = convertStringToList(newCfgs["idList"]);
    }
    newCfgs["fileName"] = newCfgs["name"];
    return newCfgs;
  };

  const convertStringToList = (str) => {
    return str ? str.split(",") : [];
  };

  const handleUpload = () => {
    let payload = new FormData();
    let newCfgs = null;
    if (sourceType === "api") {
      payload.append("sourceType", "api");
      newCfgs = structuredClone(apiConfigs);
      newCfgs["sourceType"] = "api";
      newCfgs["dsName"] = newCfgs.name;
      if (newCfgs.subType === "outgoing") {
        if (newCfgs.loginRequired === "yes") {
          if (isLoginBodyParam === "yes") {
            newCfgs.loginAPI.body = "";
          } else {
            if (newCfgs.loginAPI.method === "post") {
              const isValidJSON = checkJsonValidity(newCfgs.loginAPI.body);
              if (!isValidJSON) {
                updateSnackbarMsg(
                  "Please enter a valid JSON in login api body"
                );
                return;
              }
              newCfgs.loginAPI.body = JSON.parse(newCfgs.loginAPI.body);
            } else {
              newCfgs.loginAPI.body = "";
            }
            newCfgs.loginAPI.bodyParams = [];
          }
        }
        if (isBodyParam === "yes") {
          newCfgs.dataAPI.body = "";
        } else {
          if (newCfgs.dataAPI.method === "post") {
            const isValidJSON = checkJsonValidity(newCfgs.dataAPI.body);
            if (!isValidJSON) {
              updateSnackbarMsg("Please enter a valid JSON in data api body");
              return;
            }
            newCfgs.dataAPI.body = JSON.parse(newCfgs.dataAPI.body);
          } else {
            newCfgs.dataAPI.body = "";
          }
          newCfgs.dataAPI.bodyParams = [];
        }
      }
      const duplicatedName = checkNodeNameDuplicacy(newCfgs);
      if (duplicatedName) {
        updateSnackbarMsg("Name already taken. Please enter a unique name");
        return;
      }
    } else if (sourceType === "db") {
      newCfgs = structuredClone(databaseConfigs);
      if (databaseConfigs.dbType === "bigquery") {
        payload.append("files", uploadedFile.current);
        payload.append("fileName", databaseConfigs.name);
      }
      payload.append("sourceType", "db");
      newCfgs["sourceType"] = "db";
      newCfgs["dsName"] = newCfgs.name;
      newCfgs = getUpdatedDBFormData(newCfgs);
      const duplicatedName = checkNodeNameDuplicacy(newCfgs);
      if (duplicatedName) {
        updateSnackbarMsg("Name already taken. Please enter a unique name");
        return;
      }
    } else {
      newCfgs = structuredClone(fileConfigs);
      const duplicatedName = checkNodeNameDuplicacy(newCfgs);
      if (duplicatedName) {
        updateSnackbarMsg("Name already taken. Please enter a unique name");
        return;
      }
      if (fileConfigs.hostType === "local") {
        payload.append("files", uploadedFile.current);
        payload.append("fileName", fileConfigs.fileName);
      } else if (
        fileConfigs.hostType === "remote" &&
        fileConfigs.storageType === "sftp"
      ) {
        const isValidHostName = checkHostNameValidity(fileConfigs.host);
        if (!isValidHostName) {
          updateSnackbarMsg(
            "Invalid host name. It should be a valid IP or Url"
          );
          return;
        }
      }
      payload.append("sourceType", "file");
      newCfgs["dsName"] = newCfgs.fileName;
      newCfgs = getFileUploadFormData(newCfgs);
    }
    payload.append("dSource", selectedDatasource);
    payload.append("projectKey", dsData.projectKey);
    payload.append("projVersion", dsData.projVersion);
    payload.append("projFg", dsData.projFg);
    payload.append("dataCfg", JSON.stringify(newCfgs));
    uploadData(payload);
  };

  const updateCfgOfSelectedDatasource = () => {
    if (sourceType === "file") {
      if (fileConfigs.hostType === "local" && !uploadedFile.current) {
        goBack();
        return;
      }
    }
    handleUpload();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDatasourcesDialog}
        aria-labelledby="Datasources"
        className="datasources-dialog"
        PaperProps={{
          style: {
            background: "#212B35",
            border: "1px solid #818181",
            boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            color: "#EFF1F1",
            maxHeight: "calc(100% - 122px)",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className="responsive-dialog-title-text">
            {!showTable && (
              <div className="back-btn" onClick={() => goBack()}>
                <IconButton aria-label="configure" className="back-icon">
                  <BackIcon></BackIcon>
                </IconButton>
              </div>
            )}
            {showFeatureMap
              ? "Configure Features"
              : showValueMap
              ? "Category Mapping"
              : "Common Data Model Mapping"}
          </div>
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          {showTable ? (
            <Stack className="table">
              <Stack className="table-header" direction="row">
                <Box
                  className="header-item"
                  sx={{ width: "34%", minWidth: "34%", maxWidth: "34%" }}
                >
                  Datasource
                </Box>
                <Box className="header-item" sx={{ width: "20%" }}>
                  Type
                </Box>
                <Box className="header-item" sx={{ width: "23%" }}>
                  Data Map
                </Box>
                <Box className="header-item" sx={{ width: "23%" }}>
                  Feature Map
                </Box>
                <Box className="header-item" sx={{ width: "23%" }}>
                  Value Map
                </Box>
              </Stack>
              {dsKeys.length > 0 ? (
                dsKeys.map((dsKey) => (
                  <Stack key={dsKey} className="table-row" direction="row">
                    <Box
                      className="row-item"
                      sx={{
                        width: "34%",
                        minWidth: "34%",
                        maxWidth: "34%",
                        paddingLeft: "5px !important",
                      }}
                    >
                      <Tooltip
                        title={dataMapInfo[dsKey].dataName}
                        placement="top"
                      >
                        <Typography noWrap sx={{ fontSize: "13px" }}>
                          {dataMapInfo[dsKey].dataName}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box className="row-item" sx={{ width: "20%" }}>
                      <Tooltip
                        title={dataMapInfo[dsKey].dataCfg.cfg.sourceType}
                        placement="top"
                      >
                        <Typography noWrap sx={{ fontSize: "13px" }}>
                          {dataMapInfo[dsKey].dataCfg.cfg.sourceType}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box
                      className="row-item"
                      textAlign="center"
                      sx={{ width: "23%" }}
                    >
                      <Tooltip title="Configure" placement="top">
                        <div
                          className={`icon-button ${
                            userAccessRestricted ? "disabled-button" : ""
                          }`}
                        >
                          <IconButton
                            aria-label="configure"
                            className={`configure-icon ${
                              userAccessRestricted ? "disabled" : ""
                            }`}
                            disabled={userAccessRestricted}
                            onClick={() => configureDs(dataMapInfo[dsKey])}
                          >
                            <ConfigireDsIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Box>
                    <Box
                      className="row-item"
                      textAlign="center"
                      sx={{ width: "23%" }}
                    >
                      <Tooltip title="Configure" placement="top">
                        <div
                          className={`icon-button ${
                            userAccessRestricted ? "disabled-button" : ""
                          }`}
                        >
                          <IconButton
                            aria-label="configure"
                            className={`configure-icon ${
                              userAccessRestricted ? "disabled" : ""
                            }`}
                            disabled={userAccessRestricted}
                            onClick={() => configureFeatMap(dataMapInfo[dsKey])}
                          >
                            <ConfigireDsIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Box>
                    <Box
                      className="row-item"
                      textAlign="center"
                      sx={{ width: "23%" }}
                    >
                      <Tooltip title="Configure" placement="top">
                        <div
                          className={`icon-button ${
                            userAccessRestricted ? "disabled-button" : ""
                          }`}
                        >
                          <IconButton
                            aria-label="configure"
                            className={`configure-icon ${
                              userAccessRestricted ? "disabled" : ""
                            }`}
                            disabled={userAccessRestricted}
                            onClick={() =>
                              configureValueMap(dataMapInfo[dsKey])
                            }
                          >
                            <ConfigireDsIcon />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Box>
                  </Stack>
                ))
              ) : (
                <Box className="empty-text">Information Not available</Box>
              )}
            </Stack>
          ) : showValueMap ? (
            <Stack>
              <ConfigureValueMap
                selectedDatasource={selectedDatasource}
                contentRef={contentRef}
                dsData={dsData}
                goBack={goBack}
              />
              <Stack></Stack>
            </Stack>
          ) : showFeatureMap ? (
            <Stack>
              <ConfigureFeatureMap
                selectedDatasource={selectedDatasource}
                dsData={dsData}
                goBack={goBack}
              />
              <Stack></Stack>
            </Stack>
          ) : (
            <Stack>
              <Stack>
                <ConfigureDatasource
                  selectedDatasource={selectedDatasource}
                  currentCfgInfo={currentCfgInfo}
                  dataMapInfo={dataMapInfo}
                  sourceType={sourceType}
                  updateSourceType={updateSourceType}
                  updateSaveDisabling={updateSaveDisabling}
                  uploadedFile={uploadedFile}
                  isBodyParam={isBodyParam}
                  isLoginBodyParam={isLoginBodyParam}
                  setIsLoginBodyParam={setIsLoginBodyParam}
                  setIsBodyParam={setIsBodyParam}
                ></ConfigureDatasource>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        {selectedDatasource && !showFeatureMap && !showValueMap ? (
          <DialogActions>
            <Button
              variant="contained"
              sx={buttonStyles}
              disabled={isSaveDisabled}
              onClick={updateCfgOfSelectedDatasource}
              autoFocus
            >
              {"Save"}
            </Button>
            <Button
              autoFocus
              variant="contained"
              sx={buttonStyles}
              onClick={goBack}
            >
              Cancel
            </Button>
          </DialogActions>
        ) : (
          <></>
        )}
      </Dialog>
      <Snackbar
        open={snackbarMsg}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </div>
  );
};

export default DatasourcesDialog;
