import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  List,
  ListItem,
  FormControl,
  Button,
  Autocomplete,
  Tooltip,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch } from "react-redux";
import api from "../../apiInterceptor";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import {
  textFieldStyles,
  tabStyles,
  buttonStyles,
} from "./configureValueMapStyle";
const fetchParetoAPITestData = require("../../assets/apiTestData/fetch-pareto-test-data.json");
const perffeatvaluemapAPITestData = require("../../assets/apiTestData/success-test-data.json");

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ConfigureValueMap = ({ dsData, goBack, contentRef }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const dispatch = useDispatch();
  const { updateIsLoading, updateSnackbarMsg } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("subcategory");
  const [selected, setSelected] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedOpColName, setSelectedOpColName] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [variableMapOptions, setVariableMapOptions] = useState([]);
  const [searchFeatureColumn, setSearchFeatureColumn] = useState("");
  const [selectedFeatureColumn, setSelectedFeatureColumn] = useState("");
  const [selectedFeatureData, setSelectedFeatureData] = useState([]);
  const [featureColumns, setFeatureColumns] = useState([]);
  const filteredRows = tableData.filter((row) => {
    return row.subcategory.toString().includes(search);
  });
  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy));
  const filteredFeatureColumns = featureColumns.filter((item) =>
    item.toLowerCase().includes(searchFeatureColumn.toLowerCase())
  );

  useEffect(() => {
    handleSaveButtonDisabling();
  }, [tableData]);

  useEffect(() => {
    if (dsData.valueMapInfo) {
      setFeatureColumnData();
    }
  }, [dsData.valueMapInfo]);

  const setFeatureColumnData = () => {
    const columns = Object.keys(dsData.valueMapInfo);
    setFeatureColumns(columns);
    if (columns.length > 0) {
      handleFeatureColumnChange(columns[0]);
    }
  };

  const handleFeatureColumnChange = (feature) => {
    const featureData = dsData.valueMapInfo[feature];
    setTableData([]);
    setSelectedFeatureColumn(feature);
    setSelectedFeatureData(featureData);
    fetchParetoData(feature);
    if (featureData && featureData.length > 0) {
      const { opColName } = featureData[0];
      handleOpColNameChange(opColName, featureData);
    }
  };

  const handleOpColNameChange = (
    opColName,
    featureData = selectedFeatureData
  ) => {
    setSearch("");
    setSelected([]);
    setSelectedOpColName(opColName);
    if (!featureData) {
      featureData = selectedFeatureData;
    }
    resetTableVariableMapData();
    const opColData = featureData.find((data) => data.opColName === opColName);
    if (opColData) {
      setVariableMapOptions(opColData.valueMapInfo);
    }
  };

  const resetTableVariableMapData = () => {
    setTableData((prevTableData) =>
      prevTableData.map((item) => ({
        ...item,
        variableMap: "",
      }))
    );
  };

  const fetchParetoData = async (feature) => {
    try {
      const apiUrl = BASE_API_URL + "fetch-pareto";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        dSource: dsData.dsKey,
        projFg: dsData.projFg,
        projVersion: dsData.projVersion,
        projectKey: dsData.projectKey,
        feature: [feature],
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: fetchParetoAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const data = result[feature];
        prepareTableData(data);
        scrollToTop();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const prepareTableData = (data) => {
    const tableData = Object.entries(data).map(([key, value], index) => ({
      id: index + 1,
      subcategory: key,
      pareto: value,
      variableMap: "",
    }));
    setTableData(tableData);
  };

  const handleSaveButtonDisabling = () => {
    const saveDisabled =
      tableData.length === 0 || tableData.some((row) => !row.variableMap);
    setIsSaveDisabled(saveDisabled);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredRows.map((row) => row.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleFeatureColumnClearSearch = () => {
    setSearchFeatureColumn("");
  };

  const handleInputInSelectChange = (e, value, rowId, isInputEvent = false) => {
    if (isInputEvent) {
      value = e.target.value;
    }
    if (selected.length > 0 && selected.includes(rowId)) {
      const updatedRows = tableData.map((row) =>
        selected.includes(row.id) ? { ...row, variableMap: value } : row
      );
      setTableData(updatedRows);
    } else {
      const updatedRows = tableData.map((row) =>
        rowId === row.id ? { ...row, variableMap: value } : row
      );
      setTableData(updatedRows);
    }
  };

  const getMapCfg = () => {
    return tableData.reduce((acc, item) => {
      if (!acc[item.variableMap]) {
        acc[item.variableMap] = [];
      }
      acc[item.variableMap].push(item.subcategory.toString());
      return acc;
    }, {});
  };

  const getValueMapCfg = (opColData) => ({
    [selectedFeatureColumn]: {
      stageId: opColData.stageId,
      mapCfg: getMapCfg(),
    },
  });

  const getValueMapData = () => {
    const opColData = selectedFeatureData.find(
      (data) => data.opColName === selectedOpColName
    );
    const valueMapCfg = getValueMapCfg(opColData);
    const valueMapData = {
      dSource: opColData.dSource,
      projFg: opColData.projFg,
      projVersion: opColData.projVersion,
      projectKey: opColData.projectKey,
      valueMapCfg: valueMapCfg,
    };
    return valueMapData;
  };

  const saveValueMapData = async () => {
    try {
      const apiUrl = BASE_API_URL + "perffeatvaluemap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = getValueMapData();
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: perffeatvaluemapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        // goBack();
        updateSnackbarMsg("Value map saved successfully.");
        scrollToTop();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const scrollToTop = () => {
    if (contentRef && contentRef.current) {
      setTimeout(() => contentRef.current.scrollTo(0, 0), 1);
    }
  };

  const handleTabChange = (event, newValue) => {
    handleOpColNameChange(newValue);
  };

  return (
    <Stack>
      <Stack direction="row">
        <Stack
          width="25%"
          style={{
            border: "1px dashed #D9D9D9",
            marginRight: "20px",
            borderRadius: "8px",
            padding: "30px 5px 5px 5px",
            height: "fit-content",
          }}
        >
          <Typography marginLeft="20px" variant="h6" gutterBottom>
            CI Feature Column
          </Typography>
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            value={searchFeatureColumn}
            onChange={(e) => setSearchFeatureColumn(e.target.value)}
            margin="normal"
            sx={textFieldStyles}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ width: "35px", height: "35px" }}
                    onClick={handleFeatureColumnClearSearch}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <List>
            {filteredFeatureColumns.length > 0 ? (
              filteredFeatureColumns.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => handleFeatureColumnChange(item)}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    marginBottom: "10px",
                    padding: "10px",
                    backgroundColor:
                      selectedFeatureColumn === item ? "white" : "",
                    color:
                      selectedFeatureColumn === item ? "rgb(33, 43, 53)" : "",
                  }}
                >
                  {item}
                </ListItem>
              ))
            ) : (
              <Typography textAlign="center" color="white">
                No results found
              </Typography>
            )}
          </List>
        </Stack>
        <Stack
          width="75%"
          style={{
            borderRadius: "8px",
            background: "rgba(128 ,164 ,200 , 0.08)",
            height: "fit-content",
          }}
        >
          {selectedFeatureData.length > 0 && (
            <Stack style={{ overflowX: "auto" }}>
              <Box sx={{ overflowX: "auto", width: "100%" }}>
                <Tabs
                  value={selectedOpColName}
                  onChange={handleTabChange}
                  aria-label="tabs"
                  TabIndicatorProps={{
                    style: { backgroundColor: "#69c2d2" },
                  }}
                  sx={{ paddingX: "20px", minWidth: "max-content" }}
                >
                  {selectedFeatureData.map((data) => (
                    <Tab
                      key={data.opColName}
                      sx={tabStyles}
                      label={data.opColName}
                      value={data.opColName}
                    />
                  ))}
                </Tabs>
              </Box>
            </Stack>
          )}
          <Stack padding="20px">
            <TextField
              fullWidth
              placeholder="Search..."
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
              sx={textFieldStyles}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchIcon sx={{ color: "#D9D9D9" }} />
                  </IconButton>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      sx={{ width: "35px", height: "35px", color: "#D9D9D9" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <TableContainer
          //    sx={{ maxHeight: 400, overflowY: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    sx={{
                      backgroundColor: "rgba(128 ,164 ,200 , 0.18)",
                      borderBottom: "none",
                    }}
                  >
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < tableData.length
                      }
                      checked={
                        tableData.length > 0 &&
                        selected.length === tableData.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all" }}
                      sx={{
                        color: "#d9d9d9",
                        "&.Mui-checked": {
                          color: "#69C2D2",
                        },
                        "&.MuiCheckbox-indeterminate": {
                          color: "#69C2D2",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgba(128 ,164 ,200 , 0.18)",
                      borderBottom: "none",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "subcategory"}
                      direction={orderBy === "subcategory" ? order : "asc"}
                      onClick={() => handleRequestSort("subcategory")}
                      sx={{
                        color: "#D9D9D9",
                        "& .MuiTableSortLabel-icon": {
                          color: "#D9D9D9",
                        },
                        "&.Mui-active": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                        "&:hover": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                      }}
                    >
                      Sub Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgba(128 ,164 ,200 , 0.18)",
                      borderBottom: "none",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "pareto"}
                      direction={orderBy === "pareto" ? order : "asc"}
                      onClick={() => handleRequestSort("pareto")}
                      sx={{
                        color: "#D9D9D9",
                        "& .MuiTableSortLabel-icon": {
                          color: "#D9D9D9",
                        },
                        "&.Mui-active": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                        "&:hover": {
                          color: "#D9D9D9",
                          "& .MuiTableSortLabel-icon": {
                            color: "#D9D9D9",
                          },
                        },
                      }}
                    >
                      Pareto
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#D9D9D9",
                      backgroundColor: "rgba(128 ,164 ,200 , 0.18)",
                      borderBottom: "none",
                      minWidth: "150px",
                    }}
                  >
                    Variable Map
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      sx={{
                        textAlign: "center",
                        color: "#D9D9D9",
                        borderBottom: "none",
                      }}
                    >
                      No data available
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedRows.map((row) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <TableRow
                        key={row.id}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        selected={isItemSelected}
                        sx={{ borderBottom: "none" }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ borderBottom: "none" }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleCheckboxClick(event, row.id)
                            }
                            sx={{
                              color: "#d9d9d9",
                              "&.Mui-checked": {
                                color: "#69C2D2",
                              },
                              "&.MuiCheckbox-indeterminate": {
                                color: "#69C2D2",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ color: "#D9D9D9", borderBottom: "none" }}
                        >
                          {row.subcategory}
                        </TableCell>
                        <TableCell
                          sx={{ color: "#D9D9D9", borderBottom: "none" }}
                        >
                          {row.pareto}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#D9D9D9",
                            borderBottom: "none",
                            minWidth: "150px",
                          }}
                        >
                          <FormControl className="text-field" size="small">
                            <Autocomplete
                              freeSolo
                              disableClearable
                              options={variableMapOptions}
                              getOptionLabel={(option) => option}
                              value={row.variableMap}
                              ListboxProps={{
                                style: { maxHeight: 300, overflow: "auto" },
                              }}
                              onChange={(e, value) =>
                                handleInputInSelectChange(e, value, row.id)
                              }
                              componentsProps={{
                                paper: {
                                  sx: {
                                    backgroundColor: "#212b35",
                                    color: "#d9d9d9",
                                    borderRadius: "4px",
                                    boxShadow:
                                      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                                    "li:hover": {
                                      color: "#69c2d2",
                                    },
                                  },
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  sx={textFieldStyles}
                                  autoComplete="off"
                                  onChange={(e) =>
                                    handleInputInSelectChange(
                                      e,
                                      e.target.value,
                                      row.id,
                                      true
                                    )
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <>
                                        {params.InputProps.endAdornment}
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{
                                              width: "30px",
                                              height: "30px",
                                            }}
                                            onClick={(e) =>
                                              handleInputInSelectChange(
                                                e,
                                                "",
                                                row.id
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      </>
                                    ),
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Tooltip title={option} placement="right">
                                    <Typography noWrap>{option}</Typography>
                                  </Tooltip>
                                </li>
                              )}
                            />
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {sortedRows.length > 0 && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              gap="8px"
              paddingX="16px"
              paddingY="10px"
            >
              <Button
                variant="contained"
                sx={buttonStyles}
                disabled={isSaveDisabled}
                autoFocus
                onClick={saveValueMapData}
              >
                Save
              </Button>
              <Button
                sx={buttonStyles}
                autoFocus
                variant="contained"
                onClick={goBack}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConfigureValueMap;
