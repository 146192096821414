import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./DataCleansingHandlerNodeDialog.scss";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import {
  Snackbar,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/questionMark.svg";
import { useReactFlow } from "reactflow";
import {
  getConnectedDataSources,
  getConnectedNodes,
  fetchFeatures,
  prepareColumns,
} from "utils/utils";
import {
  buttonStyles,
  formControlStyles,
  labelStyles,
  menuItemStyles,
  selectStyles,
  chipTextFieldStyles,
  textFieldStyles,
} from "./DataCleansingHandlerNodeDialogStyles";
import CustomTooltip from "components/Tooltip/CustomTooltip";
import api from "../../apiInterceptor";
const dataclnAPITestData = require("../../assets/apiTestData/datacln-test-data.json");
const nodeInfo = require("../../assets/testdata/node-info-testdata.json");
const getprojcfgAPITestData = require("../../assets/apiTestData/getprojcfg-test-data.json");

const DataCleansingHandlerNodeDialog = (modalProps) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const modalType = "dataCleansingHandlerNodeDialog";
  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));
  const GroupItems = styled("ul")({
    padding: 0,
  });

  const { getNode } = useReactFlow();
  const formData = {
    name: "",
    description: "",
    dcKey: "",
    column: [],
    inputVal: "",
    outputVal: "",
  };
  let autoCompleteRef = {};
  const columnsInfo = [];
  const filteredOptions = useRef({});
  const [selectAllCheckboxes, setSelectAllCheckboxes] = useState({});
  const selectedNode = modalProps.selectedNode;
  const nodesFlow = modalProps.nodesFlow;
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [dataCleansingOptions, setDataCleansingOptions] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [selecteddataCleansingOption, setSelectedDataCleansingOption] =
    useState({});
  const [featureCountMismatch, setFeatureCountMismatch] = useState(false);
  const snackbarMsg = useSelector((state) => state.snackbarMsg);
  const modalForm = useSelector((state) => state.modalForm);
  const pipeline = useSelector((state) => state.pipeline);
  const nodeConfigurations =
    useSelector((state) => state.nodeConfigurations) || {};
  const detailedMsgTooltipData = useSelector(
    (state) => state.detailedMsgTooltipData
  );
  const selectedProjectKey = useSelector((state) => state.selectedProjectKey);
  const selectedProjVersion = useSelector((state) => state.selectedProjVersion);
  const selectedFeatureGroup = useSelector(
    (state) => state.selectedFeatureGroup
  );
  const dispatch = useDispatch();
  const {
    updateModalForm,
    updateNodeConfigurations,
    updateAlertMessage,
    updateSnackbarMsg,
    updateIsLoading,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    const absentDs = handleDataSourceAbsence();
    if (absentDs) {
      return;
    }
    getDataCleansingOptions();
    if (modalForm === null) {
      initializeModalForm();
    }
    getNodeLevelInfo();
    initializeFilteredOptionsAndSelectAllCheckboxes();
  }, []);

  const initializeFilteredOptionsAndSelectAllCheckboxes = () => {
    let filteredOpts = {};
    const clonedSelectAllCheckboxes = {};
    filteredOpts["column"] = [];
    clonedSelectAllCheckboxes["column"] = false;
    filteredOptions.current = filteredOpts;
    setSelectAllCheckboxes(clonedSelectAllCheckboxes);
  };

  useEffect(() => {
    let disableSave = false;
    if (!modalForm) {
      disableSave = true;
    } else {
      disableSave = handleSaveDisable();
    }
    if (disableSave) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [modalForm]);

  useEffect(() => {
    handleSelectedDataCleansingOptionChange(modalForm?.dcKey);
  }, [dataCleansingOptions]);

  const handleDataSourceAbsence = () => {
    if (connectedDataSources.length === 0) {
      updateAlertMessage("Datasource is not present");
      modalProps.closeModal(modalType);
      return true;
    }
    return false;
  };

  const initializeModalForm = () => {
    if (nodeConfigurations[selectedNode.id]) {
      const clonedNodeConfigs = structuredClone(
        nodeConfigurations[selectedNode.id]
      );
      setTimeout(() => updateModalForm(clonedNodeConfigs), 1);
    } else {
      setTimeout(() => updateModalForm(formData), 1);
    }
  };

  const getDataCleansingOptions = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: "dataCleansingHandler",
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: dataclnAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        setDataCleansingOptions(result);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const handleSelectedDataCleansingOptionChange = (dcKey) => {
    const option =
      dataCleansingOptions.find((opt) => opt.dckey === dcKey) || {};
    setSelectedDataCleansingOption(option);
  };

  const getNodeLevelInfo = async () => {
    try {
      const apiUrl = BASE_API_URL + "getprojcfg";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        projectKey: selectedProjectKey,
        projVersion: selectedProjVersion,
        projFg: selectedFeatureGroup,
        nodeType: selectedNode.data.nodeType,
      };
      updateIsLoading(true);
      let response = {};
      if (USING_TEST_DATA) {
        response = {
          data: getprojcfgAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        const nodeColsInfo = result.columnsInfo
          ? result.columnsInfo
          : nodeInfo.columnsInfo;
        initializeColumns(nodeColsInfo);
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateAlertMessage(response.data.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
        modalProps.closeModal(modalType);
      }
    } catch (error) {
      console.error(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const initializeColumns = async (nodeColsInfo) => {
    for (let connectedNode of connectedNodes) {
      const features = await fetchFeatures(
        connectedNode.dSource,
        connectedNode.stageId,
        selectedProjectKey,
        selectedProjVersion,
        selectedFeatureGroup,
        updateIsLoading,
        updateAlertMessage
      );
      if (!features) {
        updateSnackbarMsg("Couldn't fetch features. Please save and try again");
        return;
      }
      columnsInfo.push(features);
    }
    const allColumnValues = prepareColumns(columnsInfo, nodeColsInfo);
    setAllColumns(allColumnValues.columns);
  };

  const handleSaveDisable = () => {
    if (
      !modalForm.dcKey ||
      !modalForm.column ||
      !Array.isArray(modalForm.column) ||
      modalForm.column.length === 0
    ) {
      return true;
    }
    const columnLength = modalForm.column.length;
    let inputArray = [];
    let outputArray = [];
    let emptyInputArray = false;
    let emptyOutputArray = false;
    if (selecteddataCleansingOption.input === "yes") {
      inputArray = modalForm.inputVal.split(",");
      emptyInputArray = inputArray.some((input) => !input);
      if (emptyInputArray || inputArray.length !== columnLength) {
        setFeatureCountMismatch(true);
        return true;
      } else setFeatureCountMismatch(false);
    }
    if (selecteddataCleansingOption.output === "yes") {
      outputArray = modalForm.outputVal.split(",");
      emptyOutputArray = outputArray.some((output) => !output);
      if (emptyOutputArray || outputArray.length !== columnLength) {
        setFeatureCountMismatch(true);
        return true;
      } else setFeatureCountMismatch(false);
    }
    if (
      selecteddataCleansingOption.input === "yes" &&
      selecteddataCleansingOption.output === "yes"
    ) {
      if(inputArray.length !== outputArray.length) setFeatureCountMismatch(true);
      else setFeatureCountMismatch(false);
      return inputArray.length !== outputArray.length;
    }
    return false;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (e?.target?.type === "text" && name === "name") {
      if (!(value.length <= 50 && /^[a-zA-Z0-9_]*$/.test(value))) {
        return;
      }
    }
    if (name === "dcKey") {
      handleSelectedDataCleansingOptionChange(value);
    }
    const newFormData = structuredClone(modalForm);
    newFormData[name] = value;
    updateModalForm(newFormData);
  };

  const connectedDataSources = getConnectedDataSources(
    nodesFlow,
    selectedNode,
    getNode
  );
  const connectedNodes = getConnectedNodes(nodesFlow, selectedNode, getNode);

  const checkNodeNameDuplicacy = (nodeConfigs) => {
    const dsMap = pipeline.clientMetaData.dsMap;
    if (nodeConfigs.name || nodeConfigs.fileName) {
      for (let nodeId of Object.keys(dsMap)) {
        if (
          selectedNode.id !== nodeId &&
          ((nodeConfigs.name && dsMap[nodeId] === nodeConfigs.name) ||
            (nodeConfigs.fileName && nodeConfigs.fileName === dsMap[nodeId]))
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    updateSnackbarMsg(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNodeConfigurations = structuredClone(nodeConfigurations);
    const newFormData = structuredClone(modalForm);
    const duplicatedName = checkNodeNameDuplicacy(newFormData);
    if (duplicatedName) {
      updateSnackbarMsg("Name already taken. Please enter a unique name");
      return;
    }
    updateModalForm(newFormData);
    newNodeConfigurations[selectedNode.id] = structuredClone(newFormData);
    newNodeConfigurations[selectedNode.id]["inputDs"] = connectedDataSources[0];
    updateNodeConfigurations(newNodeConfigurations);
    modalProps.unHighlightProblematicNode(selectedNode);
    setTimeout(
      () => modalProps.saveConfig(newNodeConfigurations, selectedNode.id),
      1
    );
    modalProps.closeModal(modalType);
  };

  const getOptionLabel = (option) => {
    try {
      if (option.item) {
        return option.item;
      } else {
        return option;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getChipDropdownValue = (inputId) => {
    if (modalForm && modalForm[inputId]) {
      if (typeof modalForm[inputId] === "string") {
        return [modalForm[inputId]];
      } else {
        return modalForm[inputId];
      }
    } else {
      return [];
    }
  };

  const filterMultiSelectOptions = (options, inputValue, inputId) => {
    if (options.length) {
      const filteredOpts = options.filter((option) => {
        if (option.item) {
          return option.item.toLowerCase().includes(inputValue.toLowerCase());
        } else if (option.value) {
          return option.value.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return option.toLowerCase().includes(inputValue.toLowerCase());
        }
      });
      updateFilteredOptions(filteredOpts, inputId);
      return filteredOpts;
    }
    return [];
  };

  const updateFilteredOptions = (filteredOpt, inputId) => {
    filteredOptions.current[inputId] = filteredOpt;
    const clonedSelectAllCheckboxes = structuredClone(selectAllCheckboxes);
    if (clonedSelectAllCheckboxes[inputId]) {
      clonedSelectAllCheckboxes[inputId] = false;
      setSelectAllCheckboxes(clonedSelectAllCheckboxes);
    }
  };

  const isOptionEqualToValue = (option, value, options) => {
    if (options[0]?.name) {
      options = options.map((opt) => opt.name);
    } else if (options[0]?.value) {
      options = options.map((opt) => opt.value);
    }
    if (options.includes(value)) {
      if (typeof option === "object") {
        return (
          option.value === value ||
          option.item === value ||
          option.name === value ||
          value === ""
        );
      } else {
        return option === value || value === "";
      }
    }
    return value === "";
  };
  const handleChipsChange = (e, value) => {
    let newFormData = structuredClone(modalForm);
    if (Array.isArray(value)) {
      for (let index = 0; index < value.length; index++) {
        if (typeof value[index] === "object") {
          if (value[index]?.item) {
            value[index] = value[index]?.item;
          } else {
            value[index] = value[index]?.value;
          }
        }
      }
      for (let index = 0; index < value.length - 1; index++) {
        if (value[index] === value[value.length - 1]) return;
      }
    }
    if (typeof value === "object" && !Array.isArray(value)) {
      if (value?.item) {
        value = value?.item;
      } else {
        value = value?.value;
      }
    }
    newFormData["column"] = value;
    updateModalForm(newFormData);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Tooltip key={option} title={option} placement="top">
        <Chip label={option} {...getTagProps({ index })} />
      </Tooltip>
    ));

  const getError = () => {
    if(isSaveDisabled && featureCountMismatch) return true;
    return false;
  };

  const getHelperText = () => {
    if(isSaveDisabled && featureCountMismatch) return "The length of array and number of values must be equal";
    return "";
  };

  return (
    <div>
      {modalForm && (
        <div>
          <Dialog
            open={selectedNode !== null}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
            fullWidth
            PaperProps={{
              style: {
                background: "#212B35",
                border: "1px solid #818181",
                boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                color: "#EFF1F1",
                maxHeight: "calc(100% - 122px)",
              },
            }}
          >
            <DialogTitle id="responsive-dialog-title">
              <div>DataCleansing Handler</div>
              <div>
                {detailedMsgTooltipData[selectedNode.data.nodeType] ? (
                  <IconButton>
                    <CustomTooltip
                      title={detailedMsgTooltipData[selectedNode.data.nodeType]}
                    >
                      <QuestionMarkIcon
                        width={25}
                        height={25}
                      ></QuestionMarkIcon>
                    </CustomTooltip>
                  </IconButton>
                ) : (
                  <></>
                )}
                <IconButton onClick={() => modalProps.closeModal(modalType)}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: "20px !important" }}>
              <FormControl id="name" className="text-field" size="small">
                <TextField
                  type="text"
                  name="name"
                  size="small"
                  label="Name"
                  value={modalForm ? modalForm.name : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="description" className="text-field" size="small">
                <TextField
                  type="text"
                  name="description"
                  size="small"
                  label="Description"
                  value={modalForm ? modalForm.description : ""}
                  onChange={(evt) => handleChange(evt)}
                  variant="outlined"
                  sx={textFieldStyles}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl id="dcKey" sx={formControlStyles} size="small">
                <InputLabel sx={labelStyles}>DataCleansing Type</InputLabel>
                <Select
                  placeholder="DataCleansing Type"
                  name="dcKey"
                  label="DataCleansing Type"
                  value={modalForm.dcKey}
                  onChange={(evt) => handleChange(evt)}
                  required
                  size="small"
                  sx={selectStyles}
                  inputProps={{ sx: selectStyles }}
                  MenuProps={{ sx: selectStyles }}
                  autoWidth={true}
                >
                  {dataCleansingOptions.map((option, optionIndex) => (
                    <MenuItem
                      key={optionIndex}
                      value={option.dckey}
                      sx={menuItemStyles}
                    >
                      <Tooltip title={option.name} placement="right">
                        <Typography noWrap>{option.name}</Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                key="column"
                multiple
                autoHighlight
                size="small"
                id="column"
                options={allColumns}
                getOptionLabel={(option) => getOptionLabel(option)}
                filterSelectedOptions
                disableCloseOnSelect
                className="chips-dropdown mt-2"
                ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                isOptionEqualToValue={(option, value) => {
                  const options = allColumns;
                  return isOptionEqualToValue(option, value, options);
                }}
                value={getChipDropdownValue("column")}
                onChange={(e, value) => {
                  handleChipsChange(e, value);
                }}
                renderTags={renderTags}
                groupBy={(option) => option.title}
                renderGroup={(params) => {
                  return (
                    <li key={params.key}>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  );
                }}
                componentsProps={{
                  paper: {
                    sx: {
                      backgroundColor: "#212b35",
                      color: "#d9d9d9",
                      borderRadius: "4px",
                      boxShadow:
                        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                      "li:hover": {
                        color: "#69c2d2",
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Comma seperated column names"
                    placeholder="Comma seperated column names"
                    autoComplete="off"
                    sx={chipTextFieldStyles}
                    inputRef={(input) => {
                      if (input) {
                        autoCompleteRef[input.id] = input;
                      }
                    }}
                  />
                )}
                filterOptions={(options, { inputValue }) =>
                  filterMultiSelectOptions(options, inputValue, "column")
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip title={getOptionLabel(option)} placement="right">
                      <Typography noWrap>{getOptionLabel(option)}</Typography>
                    </Tooltip>
                  </li>
                )}
              />
              {selecteddataCleansingOption.input === "yes" && (
                <FormControl id="inputVal" className="text-field" size="small">
                  <TextField
                    type="text"
                    name="inputVal"
                    size="small"
                    label={selecteddataCleansingOption.inputText}
                    value={modalForm ? modalForm.inputVal : ""}
                    onChange={(evt) => handleChange(evt)}
                    variant="outlined"
                    error={getError()}
                    helperText={getHelperText()}
                    sx={textFieldStyles}
                    autoComplete="off"
                  />
                </FormControl>
              )}
              {selecteddataCleansingOption.output === "yes" && (
                <FormControl id="outputVal" className="text-field" size="small">
                  <TextField
                    type="text"
                    name="outputVal"
                    size="small"
                    label={selecteddataCleansingOption.outputText}
                    value={modalForm ? modalForm.outputVal : ""}
                    onChange={(evt) => handleChange(evt)}
                    variant="outlined"
                    error={getError()}
                    helperText={getHelperText()}
                    sx={textFieldStyles}
                    autoComplete="off"
                  />
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Stack direction="row" gap="10px" paddingX="40px" paddingY="20px">
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  autoFocus
                  sx={buttonStyles}
                  disabled={isSaveDisabled || userAccessRestricted}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  onClick={() => modalProps.closeModal(modalType)}
                  autoFocus
                  sx={buttonStyles}
                >
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarMsg}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMsg}
            action={action}
          />
        </div>
      )}
    </div>
  );
};

export default DataCleansingHandlerNodeDialog;
