import React, { useState, useEffect } from "react";
import { showSuccessAlert, getCurrentProjectDetails } from "utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import "./jobScheduler.scss";
import { useParams } from "react-router-dom";
import api from "../../apiInterceptor";
import { Box, Button, Stack } from "@mui/material";
import { buttonStyles } from "./jobSchedulerStyle";
import SetUpNewJobDialog from "../../modals/setUpNewJobDialog/setUpNewJobDialog";
import JobDetailsCard from "./jobDetailsCard/jobDetailsCard";
import DeleteDialog from "../../modals/deleteDialog/deleteDialog";
import ViewRunningJobsDialog from "../../modals/ViewRunningJobsDialog/ViewRunningJobsDialog";
import { isEmpty } from "lodash";
const getjobAPITestData = require("../../assets/apiTestData/getjob-test-data.json");
const deletejobAPITestData = require("../../assets/apiTestData/deletejob-test-data.json");
const downloadJobSummaryAPITestData = require("../../assets/apiTestData/downloadJobSummary-test-data.json");

const JobScheduler = () => {
  const COMMON_API_URL = localStorage.getItem("COMMON_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  const userAccessType = localStorage.getItem("ACCESS_TYPE");
  const userAccessRestricted = userAccessType === "view";
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const param = useParams();
  const projectKey = param.projectKey;
  const projVersion = param.projVersion;
  const projFg = param.projFg;
  const [projectName, setProjectName] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [jobsData, setJobsData] = useState([]);
  const [selectedJobdetails, setSelectedJobdetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const isDeleteDialogOpen = useSelector((state) => state.isDeleteDialogOpen);
  const isViewRunningJobsDialogOpen = useSelector(
    (state) => state.isViewRunningJobsDialogOpen
  );
  const isSetUpNewJobDialogOpen = useSelector(
    (state) => state.isSetUpNewJobDialogOpen
  );
  const dispatch = useDispatch();
  const {
    updateIsLoading,
    updateDeleteDialogStatus,
    updateViewRunningJobsDialogStatus,
    updateSetUpNewJobDialogStatus,
    updateSuccessAlertMessage,
    updateAlertMessage,
    updateExploredProject,
  } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    getJobDetails();
    setProjectNameAndDesc();
    setProjectInfo();
    return () => {};
  }, [projectKey, projVersion, projFg]);

  const setProjectInfo = () => {
    updateExploredProject({ projectKey, projVersion, projFg });
  };

  const setProjectNameAndDesc = async () => {
    try {
      const currentProjectDetails = await getCurrentProjectDetails(
        projectKey,
        updateIsLoading,
        updateAlertMessage
      );
      if (currentProjectDetails && !isEmpty(currentProjectDetails)) {
        setProjectName(currentProjectDetails.pname);
        const currentVersion = currentProjectDetails.versionInfo.find(
          (ver) => ver.vname === projVersion
        );
        const featureGroups = currentVersion.fgInfo;
        const currentFg = featureGroups.find((fg) => fg.projFg === projFg);
        setProjectDesc(currentFg.description);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getJobDetails = async () => {
    const apiUrl = COMMON_API_URL + "getjob";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = {
      project_id: projectKey,
      projectVersion: projVersion,
      projFg: projFg,
    };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = getjobAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const job = response?.data?.job_list;
        if (job && job[0]) {
          setJobsData(job);
        } else {
          setJobsData([]);
        }
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      setJobsData([]);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
      console.log(error);
    }
  };

  const setUpNewJob = () => {
    updateSetUpNewJobDialogStatus(true);
  };

  const handleJobDeletion = (job) => {
    setSelectedJobdetails(job);
    updateDeleteDialogStatus(true);
  };

  const deleteJob = async () => {
    const apiUrl = COMMON_API_URL + "deletejob";
    const headers = {
      "Content-type": "application/json",
      Accept: "text/plain",
    };
    const payload = { job_id: selectedJobdetails.job_id };
    updateIsLoading(true);
    try {
      let response = {};
      if (USING_TEST_DATA) {
        response = deletejobAPITestData;
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.status === 200) {
        const message = response.data.message;
        showSuccessAlert(message, updateSuccessAlertMessage);
        getJobDetails();
      } else if (response.status === 404) {
        if (response.data.reason) {
          updateAlertMessage(response.data.reason);
        } else {
          updateAlertMessage("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      console.log(error);
      updateIsLoading(false);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  const editJob = (job) => {
    setSelectedJobdetails(job);
    setEditMode(true);
    updateSetUpNewJobDialogStatus(true);
  };

  const showRunningJobs = (job) => {
    setSelectedJobdetails(job);
    updateViewRunningJobsDialogStatus(true);
  };

  const downloadJobSummary = async (jobDetails) => {
    try {
      const apiUrl = COMMON_API_URL + "download_job_summary";
      const startDate = jobDetails.start_date.split(" ");
      const timestamp = new Date().getTime();
      const downloadUrl =
        apiUrl +
        `?job_id=${jobDetails.job_id}&start_date=${startDate[0]}&timestamp=${timestamp}`;
      window.open(downloadUrl);
    } catch (error) {
      console.log(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateAlertMessage(errorMessage);
    }
  };

  return (
    <div>
      <Stack className="job-scheduler-container">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box className="job-scheduler-header">Job Scheduler</Box>
          <Button
            variant="contained"
            onClick={() => setUpNewJob()}
            disabled={userAccessRestricted}
            sx={buttonStyles}
          >
            Set Up New Job
          </Button>
        </Stack>
        <Stack className="project-details ">
          <div>
            {
              <div className="inventory-card card">
                <div className="card-header">
                  <span className="project-title">{projectName}</span>
                </div>
                <div className="card-body">
                  <div className="info-item">
                    <div className="info-key">Project Name</div>
                    <div className="info-value">{projectName}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-key">Project Description</div>
                    <div className="info-value">{projectDesc}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-key">Project Key</div>
                    <div className="info-value">{projectKey}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-key">Project Version</div>
                    <div className="info-value">{projVersion}</div>
                  </div>
                  <div className="info-item">
                    <div className="info-key">Feature Group</div>
                    <div className="info-value">{projFg}</div>
                  </div>
                </div>
              </div>
            }
          </div>
        </Stack>
        <div className="job-cards-container">
          {jobsData?.length > 0 ? (
            jobsData.map((jobDetails, index) => (
              <div key={index} className="card">
                <JobDetailsCard
                  jobDetails={jobDetails.payloaddata}
                  getJobDetails={getJobDetails}
                  handleJobDeletion={handleJobDeletion}
                  editJob={editJob}
                  showRunningJobs={showRunningJobs}
                  downloadJobSummary={downloadJobSummary}
                />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </Stack>
      {isSetUpNewJobDialogOpen ? (
        <SetUpNewJobDialog
          setEditMode={setEditMode}
          jobDetails={selectedJobdetails}
          isEditMode={editMode}
          projectKey={projectKey}
          projVersion={projVersion}
          projFg={projFg}
          getJobDetails={getJobDetails}
        />
      ) : (
        <></>
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          deletableItem={selectedJobdetails}
          deleteHandler={deleteJob}
          deletionType={"job"}
        />
      )}
      {isViewRunningJobsDialogOpen && (
        <ViewRunningJobsDialog jobDetails={selectedJobdetails} />
      )}
    </div>
  );
};

export default JobScheduler;
