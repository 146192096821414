import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { useDispatch } from "react-redux";
import "./configureFeatureMap.scss";
import api from "../../apiInterceptor";
import { textFieldStyles } from "./configureFeatureMapStyle";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
const fetchdatafeaturemapAPITestData = require("../../assets/apiTestData/fetchdatafeaturemap-test-data.json");
const perffeatmapAPITestData = require("../../assets/apiTestData/success-test-data.json");

const ConfigureFeatureMap = ({ selectedDatasource, dsData, goBack }) => {
  const BASE_API_URL = localStorage.getItem("BASE_API_URL");
  let USING_TEST_DATA = localStorage.getItem("USING_TEST_DATA");
  USING_TEST_DATA =
    USING_TEST_DATA === "true" || USING_TEST_DATA === true ? true : false;
  const [savedFeatures, setSavedFeatures] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [rawFeatures, setRawFeatures] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const dispatch = useDispatch();
  const { updateIsLoading, updateSnackbarMsg } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const buttonStyles = {
    color: "white",
    background: "#69c2d2",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    textTransform: "none",
    ":hover": {
      background: "#40bcd2",
    },
    ":disabled": {
      background: "rgb(213 205 205 / 59%)",
    },
  };

  useEffect(() => {
    setFeaturesList(dsData.features || []);
    getFeatureMapData();
  }, []);

  useEffect(() => {
    handleSaveButtonDisabling();
  }, [savedFeatures]);

  const handleSaveButtonDisabling = () => {
    let saveDisabled = false;
    for (let feature of featuresList) {
      if (!savedFeatures[feature]) {
        saveDisabled = true;
        break;
      }
    }
    setIsSaveDisabled(saveDisabled);
  };

  const getFeatureMapData = async () => {
    try {
      const apiUrl = BASE_API_URL + "fetchdatafeaturemap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        dSource: dsData.dsKey,
        projFg: dsData.projFg,
        projVersion: dsData.projVersion,
        projectKey: dsData.projectKey,
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: fetchdatafeaturemapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        const result = response.data.data.posts[0];
        if (result.featureCfg.rawFeatures) {
          if (result.featureCfg.rawFeatures.length > 0) {
            setRawFeatures(result.featureCfg.rawFeatures);
            setSavedFeatures({});
          } else {
            goBack();
            updateSnackbarMsg("No features available for selection.");
          }
        } else {
          setSavedFeatures(result.featureCfg);
        }
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const handleFeatureChange = (e, feature, newValue) => {
    const newSavedFeatures = { ...savedFeatures, [feature]: newValue };
    setSavedFeatures(newSavedFeatures);
  };

  const handleFeatureClear = (feature) => {
    const newSavedFeatures = { ...savedFeatures };
    delete newSavedFeatures[feature];
    setSavedFeatures(newSavedFeatures);
  };

  const saveFeatureMapData = async () => {
    try {
      const apiUrl = BASE_API_URL + "perffeatmap";
      const headers = {
        "Content-type": "application/json",
        Accept: "text/plain",
      };
      const payload = {
        dSource: dsData.dsKey,
        projFg: dsData.projFg,
        projVersion: dsData.projVersion,
        projectKey: dsData.projectKey,
        featureCfg: savedFeatures,
      };
      let response = {};
      updateIsLoading(true);
      if (USING_TEST_DATA) {
        response = {
          data: perffeatmapAPITestData,
        };
      } else {
        response = await api.post(apiUrl, payload, { headers: headers });
      }
      updateIsLoading(false);
      if (response.data.status === 200) {
        goBack();
      } else if (response.data.status === 404) {
        if (response.data.data.reason) {
          updateSnackbarMsg(response.data.data.reason);
        } else {
          updateSnackbarMsg("Something went wrong. Please try again later");
        }
      }
    } catch (error) {
      updateIsLoading(false);
      console.error(error);
      const errorMessage =
        "Something went wrong. Please contact the administrator";
      updateSnackbarMsg(errorMessage);
    }
  };

  const getFilteredFeatures = (currentFeature) => {
    return rawFeatures.filter(
      (rawFeature) =>
        !Object.values(savedFeatures).includes(rawFeature) ||
        savedFeatures[currentFeature] === rawFeature
    );
  };

  const GroupHeader = styled("div")(({ theme, padding = "4px 10px" }) => ({
    position: "sticky",
    top: "-8px",
    zIndex: 10000,
    padding: padding,
    color: "#FFFFFF",
    backgroundColor: "rgb(68 79 91)",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });

  return (
    <div>
      <div className="feature-map-table-container">
        <Stack className="table">
          <Stack className="table-header" direction="row">
            <Box className="header-item" sx={{ width: "50%" }}>
              Common Data Model Features
            </Box>
            <Box className="header-item" sx={{ width: "50%" }}>
              User Features
            </Box>
          </Stack>
          {featuresList.length > 0 ? (
            featuresList.map((feature) => (
              <Stack key={feature} className="table-row" direction="row">
                <Box
                  className="row-item"
                  sx={{ width: "50%", paddingLeft: "5px !important" }}
                >
                  <Tooltip title={feature} placement="top">
                    <Typography noWrap sx={{ fontSize: "13px" }}>
                      {feature}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box className="row-item" sx={{ width: "50%" }}>
                  {rawFeatures.length > 0 ? (
                    <Autocomplete
                      autoHighlight
                      size="small"
                      id="tags-outlined"
                      options={getFilteredFeatures(feature)}
                      autoComplete
                      includeInputInList
                      ListboxProps={{
                        style: { maxHeight: 300, overflow: "auto" },
                      }}
                      value={savedFeatures[feature] || null}
                      onChange={(event, newValue) =>
                        handleFeatureChange(event, feature, newValue)
                      }
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            key={index}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      groupBy={(option) => option.title}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          <GroupHeader padding={"0px"}>
                            {params.group}
                          </GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#212b35",
                            color: "#d9d9d9",
                            borderRadius: "4px",
                            boxShadow:
                              "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
                            "li:hover": {
                              color: "#69c2d2",
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <Tooltip
                          title={savedFeatures[feature] || ""}
                          placement="right"
                        >
                          <TextField
                            {...params}
                            size="small"
                            label={"Select feature"}
                            placeholder="Select option"
                            autoComplete="off"
                            sx={textFieldStyles}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <IconButton
                                  sx={{
                                    width: "30px",
                                    height: "30px",
                                  }}
                                  onClick={() => handleFeatureClear(feature)}
                                  size="small"
                                >
                                  <CloseIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </Tooltip>
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Tooltip title={option} placement="right">
                            <Typography noWrap>{option}</Typography>
                          </Tooltip>
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option === value || value === ""
                      }
                    />
                  ) : (
                    <Tooltip title={savedFeatures[feature]} placement="top">
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {savedFeatures[feature]}
                      </Typography>
                    </Tooltip>
                  )}
                </Box>
              </Stack>
            ))
          ) : (
            <Box className="empty-text">Information Not available</Box>
          )}
        </Stack>
      </div>
      {rawFeatures.length > 0 && (
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          gap="8px"
          marginTop="10px"
        >
          <Button
            variant="contained"
            sx={buttonStyles}
            disabled={isSaveDisabled}
            onClick={saveFeatureMapData}
            autoFocus
          >
            Save
          </Button>
          <Button
            autoFocus
            variant="contained"
            sx={buttonStyles}
            onClick={goBack}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default ConfigureFeatureMap;
